import axios from 'axios'

// ** Request Interceptor
axios.interceptors.request.use(
    (config) => {
        config.baseURL = `${process.env.REACT_APP_API_URL}`
        config.headers.XRequestedWith = 'XMLHTTPRequest'
        config.withCredentials = true
        return config
    },
    (error) => Promise.reject(error)
)
